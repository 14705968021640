import { FC, memo, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { FeedTypes } from 'stores/feed-filters/enums/feed-types.enum';

import { ISliderItem } from 'containers/feed-filters/feed.config';

import { BarActionType } from 'components/bars/bar-action.type';

import styles from './feeds-select-slider.module.less';

export interface IFeedsSelectSliderProps {
  items: ISliderItem[];
  fullWidth: boolean;
  onItemClick: (action: BarActionType, value: FeedTypes) => void;
}

export const FeedsSelectSlider: FC<IFeedsSelectSliderProps> = memo(
  (props: IFeedsSelectSliderProps) => {
    const { items, fullWidth, onItemClick } = props;

    const feedsSelectSliderWrapperClasses = useMemo(
      () =>
        cn(styles.FeedsSelectSlider, {
          [styles['FeedsSelectSlider--full-width']]: fullWidth,
        }),
      [fullWidth],
    );

    const getItemClasses = useCallback(
      (isSelected: boolean) =>
        cn(styles.FeedsSelectSlider__Item, {
          [styles['FeedsSelectSlider__Item--selected']]: isSelected,
        }),
      [],
    );

    const handleItemClick = useCallback(
      (action: BarActionType, value: FeedTypes) => () => {
        onItemClick(action, value);
      },
      [onItemClick],
    );

    return (
      <div className={feedsSelectSliderWrapperClasses}>
        {items.map((item) => {
          return (
            <button
              key={item.label}
              className={getItemClasses(item.isSelected)}
              onClick={handleItemClick(item.action, item.value)}
            >
              {item.label}
            </button>
          );
        })}
      </div>
    );
  },
);
