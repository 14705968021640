import { memo, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { FeedTypes } from 'stores/feed-filters/enums/feed-types.enum';
import { ISingleCategoryRecord } from 'stores/feed-filters/interfaces/feed-filters.interface';

import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { FeedsSelectSlider } from 'components/ui/feeds-select-slider/feeds-select-slider.component';
import { MultipleChooseSectionCheckboxesGroup } from 'components/ui/form-fields/multiple-choose-section-checkboxes/multiple-choose-section-checkboxes-group.component';

import { BarAction } from '../bars/bar-action.enum';
import { BarActionType } from '../bars/bar-action.type';

import { formSelectedSliderItems } from './form-selected-slider-items.util';

import styles from './feed-filter.module.less';

export interface IFeedFilterProps {
  isShowFeedsToggle: boolean;
  filters: Maybe<ISingleCategoryRecord[]>;
  onResetFilters: () => void;
  onToggleCategoryItem: (categoryId: string, tagId: string) => void;
  onToggleCategory: (categoryId: string) => void;
  onChangeFeeds: (value: FeedTypes) => void;
  onToggleCollapseItemOpen?: (isOpened: boolean) => void;
}

export const FeedFilters = memo((props: IFeedFilterProps) => {
  const { isShowFeedsToggle, filters, onChangeFeeds } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const sliderItems = useMemo(
    () => formSelectedSliderItems(location.pathname, true),
    [location.pathname],
  );

  const handleItemClick = useCallback(
    (action: BarActionType, value: FeedTypes) => {
      if (action.type === BarAction.Link) {
        onChangeFeeds(value);
        navigate(action.path);
      }
    },
    [navigate, onChangeFeeds],
  );

  if (!filters) {
    return null;
  }

  return (
    <div className={styles.FeedFilterWrapper}>
      {isShowFeedsToggle && (
        <div className={styles.FeedFilterWrapper__Slider}>
          <FeedsSelectSlider fullWidth items={sliderItems} onItemClick={handleItemClick} />
        </div>
      )}
      <div className={styles.FeedFilterWrapper__Header}>
        <div className={styles.Label}>Filter</div>
        <Button size={ButtonSize.Small} onClick={props.onResetFilters} theme={ButtonTheme.Text}>
          Reset
        </Button>
      </div>
      <div className={styles.FeedFilterWrapper__Main}>
        <MultipleChooseSectionCheckboxesGroup
          filters={filters}
          onToggleCategory={props.onToggleCategory}
          onToggleCategoryItem={props.onToggleCategoryItem}
          onToggleCollapseItemOpen={props.onToggleCollapseItemOpen}
        />
      </div>
    </div>
  );
});
