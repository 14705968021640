import { FC, memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import cn from 'classnames';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';
import { PostsFeedType } from 'services/posts/enums/posts-feed-type.enum';
import { IReportReason } from 'services/reports/interfaces/report-reason.interface';

import {
  IGameContent,
  IPlayerContent,
} from 'stores/content-cards/interfaces/content-cards.interface';
import { POSTS_AMOUNT_BEFORE_PRE_LOAD } from 'stores/entries/constants/entries.contstants';
import { FeedTypes } from 'stores/feed-filters/enums/feed-types.enum';
import { OpenPreviewType } from 'stores/gallery/types/gallery.type';
import { IHeadline } from 'stores/headlines/interfaces/headline.interface';
import { IPost } from 'stores/posts/interfaces/post.interface';
import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';

import { ISharePayload } from 'containers/posts/interfaces/share-payload.interface';

import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { useInfiniteScroll } from 'hooks/use-infinite-scroll';

import { PostsTheme } from 'components/posts/post-card/enums/posts-theme.enum';
import { PostCard } from 'components/posts/post-card/post-card.component';
import { ReactionDirectionType } from 'components/reactions/reactions/reactions.component';
import { Loader } from 'components/ui/loader/loader.component';
import { IPollVoteData } from 'components/ui/poll/interfaces/poll.interface';
import { RichTextContentTypes } from 'components/ui/rich-text-preview/rich-text-preview.component';

import { PostCardFeedsTheme } from '../post-card/post-card-feeds/post-card-feeds.component';

import { ContentCardsGroup } from './content-cards-group/content-cards-group.component';
import { FeedEmptyState } from './posts-feed-empty-state/feed-empty-state.component';
import { ShortcutHeadlines } from './shortcut-headlines/shortcut-headlines.component';
import { SHORTCUT_HEADLINES_POSITION } from './shortcut-headlines/shortcut-headlines.config';

import styles from './posts-feed.module.less';

interface IPostFeedProps {
  feedsTheme: PostCardFeedsTheme;
  headlines: IHeadline[];
  playerSlug: Maybe<string>;
  hasMore: boolean;
  team: Maybe<ITeamsStats>;
  entries: IPost[];
  fetched: boolean;
  fetching: boolean;
  isPulledRefresher: boolean;
  userId: Maybe<string>;
  visitedPostId: Maybe<string>;
  activeFeed: Maybe<FeedTypes>;
  reportReasons: Maybe<IReportReason[]>;
  gameContentCards: IGameContent[];
  playerContentCards: IPlayerContent[];
  onLoadMore: () => void;
  onDeletePost: (id: string, teamId: Maybe<number>) => void;
  onShareClick: (payload: ISharePayload) => void;
  onPollVote: (voteData: IPollVoteData) => void;
  onPollExpired: (postId: string) => void;
  onVotePost?: (
    postId: string,
    reaction: string,
    type: ReactionDirectionType,
    teamId?: number,
    playerId?: number,
  ) => void;
  onOpenPreview: OpenPreviewType;
  onResetCurrentPostId?: (id: Maybe<string>) => void;
  onGetFavoriteTeam: (teamId: Maybe<number>) => Maybe<ITeamsStats>;
  onGetFavoritePlayer: (playerId: Maybe<number>) => Maybe<IPlayerStats>;
  onTeamClick: (teamId: number) => void;
  onPlayerClick: (slug: string) => void;
  onOpenFilters: () => void;
  onFollowTeamsClick: () => void;
  triggerAuthorisationCheck: () => boolean;
  onCloseReportsPopup: (postId: string) => void;
  onTogglePostToBookmarks: (postId: string, teamId?: number, playerId?: number) => void;
  onSendReport?: (
    postId: string,
    reasonId: number,
    teamId?: number,
    playerId?: number,
  ) => Promise<boolean>;
}

export const PostsFeed: FC<IPostFeedProps> = memo((props: IPostFeedProps) => {
  const {
    feedsTheme,
    headlines,
    playerSlug,
    userId,
    activeFeed,
    hasMore,
    entries,
    team,
    fetched,
    fetching,
    reportReasons,
    isPulledRefresher,
    gameContentCards,
    playerContentCards,
    onShareClick,
    onLoadMore,
    onGetFavoriteTeam,
    onGetFavoritePlayer,
  } = props;

  const navigate = useNavigate();

  const isFavoritesFeed = useMemo(() => activeFeed === FeedTypes.Favorites, [activeFeed]);
  const isPlayerFeed = useMemo(() => activeFeed === FeedTypes.Player, [activeFeed]);
  const isNBAFeed = useMemo(() => activeFeed === FeedTypes.NBA, [activeFeed]);

  const setIntersectionObserver = useInfiniteScroll(hasMore, onLoadMore);

  const shouldShowEmptyState = useMemo(() => {
    if (!fetched) {
      return false;
    }

    return !entries.length;
  }, [entries.length, fetched]);

  const hasContentCards = useMemo(
    () => !!gameContentCards.length || !!playerContentCards.length,
    [gameContentCards, playerContentCards],
  );

  const isDeafLoading = useMemo(
    () => fetching && !!entries.length && !isPulledRefresher,
    [fetching, entries, isPulledRefresher],
  );

  const isFullRefreshLoader = useMemo(
    () => !fetched && !entries.length && !hasContentCards,
    [fetched, entries, hasContentCards],
  );

  const postFeedCardsClasses = useMemo(
    () =>
      cn(styles.PostsFeed__Cards, {
        [styles['PostsFeed__Cards--is-height']]: !fetched && !hasContentCards,
        [styles['PostsFeed__Cards--empty-player-feed']]: isPlayerFeed && !entries.length,
        [styles['PostsFeed__Cards--player-feed']]: isPlayerFeed,
      }),
    [fetched, hasContentCards, isPlayerFeed, entries],
  );

  const handleShowModal = useCallback(
    (payload: ISharePayload) => () => {
      onShareClick(payload);
    },
    [onShareClick],
  );

  const handleGameContentClick = useCallback(
    (gameId: string, teamsName: string, gameDate: string) => {
      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type:
            FeedTypes.NBA === activeFeed
              ? ButtonTapsEnum.NbaFeedLiveTeam
              : ButtonTapsEnum.FavoritesLiveTeam,
          team: teamsName,
          game_date: gameDate,
        },
      });

      navigate(
        getPath(paths.ARENA_GAME, {
          [paths.GAME_ID_PARAM]: gameId,
        }),
      );
    },
    [activeFeed, navigate],
  );

  const handlePlayerContentClick = useCallback(
    (slug: string, gameId: string, playerName: string, gameDate: string) => {
      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type:
            FeedTypes.NBA === activeFeed
              ? ButtonTapsEnum.NbaFeedLivePlayer
              : ButtonTapsEnum.FavoritesLivePlayer,
          player: playerName,
          game_date: gameDate,
        },
      });

      navigate(
        getPath(paths.PLAYER_PERFORMANCE, {
          [paths.PLAYER_SLUG_PARAM]: slug,
          [paths.GAME_ID_PARAM]: gameId,
        }),
      );
    },
    [activeFeed, navigate],
  );

  const handlePlayerPostClick = useCallback(
    (playerName: string, gameDate: string) => {
      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type:
            FeedTypes.NBA === activeFeed
              ? ButtonTapsEnum.NbaFeedPastPlayer
              : ButtonTapsEnum.FavoritesPastPlayer,
          player: playerName,
          game_date: gameDate,
        },
      });
    },
    [activeFeed],
  );

  const handleGamePostClick = useCallback(
    (teamsName: string, gameDate: string) => {
      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type:
            FeedTypes.NBA === activeFeed
              ? ButtonTapsEnum.NbaFeedPastTeam
              : ButtonTapsEnum.FavoritesPastTeam,
          team: teamsName,
          game_date: gameDate,
        },
      });
    },
    [activeFeed],
  );

  const handlePlayerProfileClick = useCallback(() => {
    if (playerSlug) {
      navigate(
        getPath(paths.PLAYER_PROFILE_OVERFIEW, {
          [paths.PLAYER_SLUG_PARAM]: playerSlug,
        }),
      );
    }
  }, [playerSlug, navigate]);

  return (
    <div className={postFeedCardsClasses}>
      {!isPlayerFeed && <Loader isShow={isDeafLoading} isFeedRefresh />}
      <Loader isShow={isFullRefreshLoader} />
      {shouldShowEmptyState && (
        <FeedEmptyState
          isPlayerFeed={isPlayerFeed}
          isFavoritesFeed={isFavoritesFeed}
          onFollowTeamsClick={props.onFollowTeamsClick}
          onOpenFilters={props.onOpenFilters}
          onPlayerProfileClick={handlePlayerProfileClick}
        />
      )}
      <ContentCardsGroup
        activeFeed={activeFeed}
        contentGames={gameContentCards}
        contentPlayers={playerContentCards}
        onGameContentClick={handleGameContentClick}
        onPlayerContentClick={handlePlayerContentClick}
      />
      {!shouldShowEmptyState &&
        entries.map((post: IPost, index: number) => (
          <div
            key={post.uuid}
            ref={
              entries.length < index + POSTS_AMOUNT_BEFORE_PRE_LOAD
                ? setIntersectionObserver
                : undefined
            }
            className={styles.PostsFeed__Card}
          >
            <PostCard
              feedsTheme={feedsTheme}
              theme={PostsTheme.Regular}
              editorType={RichTextContentTypes.WithReadMore}
              isPulledRefresher={isPulledRefresher}
              userId={userId}
              team={team}
              activeFeed={activeFeed}
              isBookmarked={post.isBookmarked}
              userInfo={post.user}
              reportReasons={reportReasons}
              postInfo={{
                embeddedLink: post.embeddedLink,
                bookmarksCount: post.bookmarksCount,
                commentsCount: post.commentsAmount,
                id: post.uuid,
                createdDates: post.formattedDates,
                content: post.content,
                reactionsSum: post.votesTotal,
                sharesCount: post.sharesCount,
                title: post.title,
                reactions: {
                  positive: post.reactionsTotal.upVote,
                  negative: post.reactionsTotal.downVote,
                },
                attachments: post.attachments,
                source: post.source,
                contentCard: post.contentCard,
                feeds: post.feeds,
              }}
              favoriteTeam={onGetFavoriteTeam(post.user.favoriteTeamId)}
              favoritePlayer={onGetFavoritePlayer(post.user.favoritePlayerId)}
              onOpenPreview={props.onOpenPreview}
              onDeletePost={props.onDeletePost}
              onVotePost={props.onVotePost}
              onTogglePostToBookmarks={props.onTogglePostToBookmarks}
              onShareClick={handleShowModal({
                postId: post.uuid,
                teamId:
                  post.feeds.feed?.type === PostsFeedType.Team ? post.feeds.feed.teamId : undefined,
                playerId:
                  post.feeds.feed?.type === PostsFeedType.Player
                    ? post.feeds.feed.playerId
                    : undefined,
              })}
              onPollVote={props.onPollVote}
              onPollExpired={props.onPollExpired}
              visitedPostId={props.visitedPostId}
              onResetCurrentPostId={props.onResetCurrentPostId}
              onTeamClick={props.onTeamClick}
              onPlayerClick={props.onPlayerClick}
              onSendReport={props.onSendReport}
              onCloseReportsPopup={props.onCloseReportsPopup}
              triggerAuthorisationCheck={props.triggerAuthorisationCheck}
              onPlayerPostClick={handlePlayerPostClick}
              onGamePostClick={handleGamePostClick}
            />
            {isNBAFeed && SHORTCUT_HEADLINES_POSITION === index && (
              <ShortcutHeadlines headlines={headlines} />
            )}
          </div>
        ))}
    </div>
  );
});
