export enum ScreenNamesEnum {
  OnboardingStart = 'onboarding_start',

  SignUp = 'sign_up',
  SignUpSubmitPassword = 'sign_up_submit_password',

  Login = 'login',
  LoginForgotPassword = 'login_forgot_password',

  HomeFeedFavorites = 'home_feed_favorites',
  HomeFeedNBA = 'home_feed_NBA',
  HomeFeedHeadlines = 'home_feed_headlines',
  HomeScores = 'home_scores',
  HomeStats = 'home_stats',
  HomeStandings = 'home_standings',

  FollowTeamsAndPlayersTeams = 'follow_teams_players_teams_tab',
  FollowTeamsAndPlayersPlayers = 'follow_teams_players_players_tab',

  ArenaChat = 'arena_chat',
  ArenaPlays = 'arena_plays',
  ArenaPolls = 'arena_polls',
  ArenaBoxScore = 'arena_box_scores',
  ArenaTeamStats = 'arena_team_stats',

  PlayerProfile = 'player_profile',
  PlayerProfileProfile = 'player_profile_profile',
  PlayerProfileStats = 'player_profile_stats',
  PlayerProfileSplits = 'player_profile_splits',
  PlayerProfileGames = 'player_profile_games',
  PlayerProfileFeed = 'player_profile_feed',

  TeamProfileFeed = 'team_profile_feed',
  TeamProfileStats = 'team_profile_stats',
  TeamProfileScores = 'team_profile_scores',
  TeamProfileProfile = 'team_profile_profile',
  TeamProfileSchedulue = 'team_profile_schedulue',

  CreatPost = 'create_post',

  UserProfile = 'user_profile',

  PlayerPerformance = 'player_performance',
  RankingsView = 'rankings',
}
