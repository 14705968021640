import { memo, useMemo } from 'react';

import {
  IGameContent,
  IPlayerContent,
} from 'stores/content-cards/interfaces/content-cards.interface';
import { FeedTypes } from 'stores/feed-filters/enums/feed-types.enum';

import { GameContent } from 'components/posts/game-content/game-content.component';
import { PlayerContent } from 'components/posts/player-content/player-content.component';

import styles from './content-cards-group.module.less';

interface IContentCardsGroupProps {
  activeFeed: Maybe<FeedTypes>;
  contentGames: IGameContent[];
  contentPlayers: IPlayerContent[];
  onGameContentClick: (gameId: string, teamsName: string, gameDate: string) => void;
  onPlayerContentClick: (
    slug: string,
    gameId: string,
    playerName: string,
    gameDate: string,
  ) => void;
}

export const ContentCardsGroup = memo((props: IContentCardsGroupProps) => {
  const { activeFeed, contentGames, contentPlayers } = props;

  const playerStaticText = useMemo(() => {
    if (FeedTypes.NBA === activeFeed) {
      return 'Top performers of the night';
    }

    if (FeedTypes.Favorites === activeFeed) {
      return 'Your Favorite Players';
    }

    return '';
  }, [activeFeed]);

  const gameStaticText = useMemo(() => {
    if (FeedTypes.NBA === activeFeed) {
      return 'Top Scoring Game';
    }

    if (FeedTypes.Favorites === activeFeed) {
      return 'Your Favorite Teams';
    }

    return '';
  }, [activeFeed]);

  const isAvailableContentCards = useMemo(
    () => FeedTypes.NBA === activeFeed || FeedTypes.Favorites === activeFeed,
    [activeFeed],
  );

  const isPlayerContentDisplayed = useMemo(() => !!contentPlayers.length, [contentPlayers]);

  const isGameContentDisplayed = useMemo(() => !!contentGames.length, [contentGames]);

  if (!isAvailableContentCards) {
    return null;
  }

  return (
    <>
      {isPlayerContentDisplayed && (
        <div className={styles.ContentCards}>
          <span className={styles.ContentCards__Title}>{playerStaticText}</span>
          {contentPlayers.map((content) => (
            <PlayerContent
              key={`${content.gameId}_${content.playerId}`}
              contentData={content}
              onClick={props.onPlayerContentClick}
            />
          ))}
        </div>
      )}
      {isGameContentDisplayed && (
        <div className={styles.ContentCards}>
          <span className={styles.ContentCards__Title}>{gameStaticText}</span>
          {contentGames.map((content) => (
            <GameContent
              key={content.gameId}
              contentData={content}
              onClick={props.onGameContentClick}
            />
          ))}
        </div>
      )}
    </>
  );
});
