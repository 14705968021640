import { FeedTypes } from 'stores/feed-filters/enums/feed-types.enum';

import * as paths from 'routes/paths.constants';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';

export interface ISliderItem {
  label: string;
  isSelected: boolean;
  action: BarActionType;
  value: FeedTypes;
}

export const FEED_SLIDER_ITEMS: ISliderItem[] = [
  {
    label: 'NBA',
    action: {
      type: BarAction.Link,
      path: paths.HOME_FEED,
    },
    value: FeedTypes.NBA,
    isSelected: false,
  },
  {
    label: 'Favorites',
    action: {
      type: BarAction.Link,
      path: paths.FAVORITES_FEED,
    },
    value: FeedTypes.Favorites,
    isSelected: false,
  },
  {
    label: 'Headlines',
    action: {
      type: BarAction.Link,
      path: paths.HOME_HEADLINES,
    },
    value: FeedTypes.Headlines,
    isSelected: false,
  },
];

export const FILTER_SLIDER_ITEMS: ISliderItem[] = [
  {
    label: 'NBA',
    action: {
      type: BarAction.Link,
      path: paths.HOME_FEED,
    },
    value: FeedTypes.NBA,
    isSelected: false,
  },
  {
    label: 'Favorites',
    action: {
      type: BarAction.Link,
      path: paths.FAVORITES_FEED,
    },
    value: FeedTypes.Favorites,
    isSelected: false,
  },
];
