import { memo, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Browser } from '@capacitor/browser';
import cn from 'classnames';

import { ContentCardsType } from 'services/content-cards/enums/content-cards-type.enum';
import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';
import { IEventParams } from 'services/google-analytic/interfaces/event-payload.interface';
import { PostSourceType } from 'services/posts/enums/post-source-type.enum';
import { PostsFeedType } from 'services/posts/enums/posts-feed-type.enum';
import { IReportReason } from 'services/reports/interfaces/report-reason.interface';

import { IBasePublicationAuthor } from 'stores/entries/interfaces/entries-autor.interface';
import { FeedTypes } from 'stores/feed-filters/enums/feed-types.enum';
import { OpenPreviewType } from 'stores/gallery/types/gallery.type';
import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { getPath } from 'helpers/get-path.util';
import { userReportHandler } from 'helpers/user-report-handler.util';
import * as paths from 'routes/paths.constants';

import { useMainProvider } from 'hooks/use-main-provider';
import { useResponsive } from 'hooks/use-responsive';
import { useScrollTo } from 'hooks/use-scroll-to';

import { Attachments } from 'components/attachments/attachments.component';
import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import {
  BookmarkFooter,
  BookmarkFooterTheme,
  BookmarkFooterType,
} from 'components/bookmarks/bookmark-footer/bookmark-footer.component';
import { EmbeddedAttachment } from 'components/embedded-attachment/embedded-attachment.component';
import { ConfirmationModal } from 'components/modals/confirmation-modal/confirmation-modal.component';
import { GameContent } from 'components/posts/game-content/game-content.component';
import { PlayerContent } from 'components/posts/player-content/player-content.component';
import { IFormattedDates } from 'components/posts/post-card/interfaces/post-info.interface';
import { ReactionDirectionType } from 'components/reactions/reactions/reactions.component';
import { ReportsPopup } from 'components/reports/reports-popup/reports-popup.component';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { ImageWithBadge } from 'components/ui/image-with-badge/image-with-badge.component';
import { IPollVoteData } from 'components/ui/poll/interfaces/poll.interface';
import {
  RichTextContentTypes,
  RichTextPreview,
} from 'components/ui/rich-text-preview/rich-text-preview.component';
import { MAX_RICH_TEXT_LINES } from 'components/ui/rich-text-preview/rich-text-preview.config';
import { YouTubePlayer } from 'components/youtube-player/youtube-player.component';

import { PostsTheme } from './enums/posts-theme.enum';
import { IPostInfo } from './interfaces/post-info.interface';
import { PostCardActionsBar } from './post-card-actions-bar/post-card-actions-bar.component';
import { PostCardFeeds, PostCardFeedsTheme } from './post-card-feeds/post-card-feeds.component';
import { PostCardHeader } from './post-card-header/post-card-header.component';

import styles from './post-card.module.less';

export interface IPostCardProps {
  theme: PostsTheme;
  feedsTheme: PostCardFeedsTheme;
  isLinksClickable?: boolean;
  isPulledRefresher?: boolean;
  userInfo: IBasePublicationAuthor;
  favoriteTeam: Maybe<ITeamsStats>;
  favoritePlayer: Maybe<IPlayerStats>;
  postInfo: IPostInfo;
  hasExtendedActions?: boolean;
  editorType: RichTextContentTypes;
  bookmarkDate?: IFormattedDates;
  reportReasons?: Maybe<IReportReason[]>;
  isBookmarked: boolean;
  team: Maybe<ITeamsStats>;
  visitedPostId?: Maybe<string>;
  isForSidePanel?: boolean;
  userId: Maybe<string>;
  activeFeed?: Maybe<FeedTypes>;
  onVotePost?: (
    postId: string,
    reaction: string,
    type: ReactionDirectionType,
    teamId?: number,
    playerId?: number,
  ) => void;
  onShareClick: () => void;
  onPollVote: (voteData: IPollVoteData) => void;
  onPollExpired: (postId: string) => void;
  onOpenPreview: OpenPreviewType;
  onResetCurrentPostId?: (id: Maybe<string>) => void;
  onDeletePost?: (id: string, teamId: Maybe<number>) => void;
  onCloseBookmarks?: () => void;
  onTeamClick: (teamId: number) => void;
  onPlayerClick: (slug: string) => void;
  triggerAuthorisationCheck?: () => boolean;
  onCloseReportsPopup?: (postId: string) => void;
  onPlayerPostClick?: (playerName: string, gameDate: string) => void;
  onGamePostClick?: (teamsName: string, gameDate: string) => void;
  onTogglePostToBookmarks: (id: string, teamId?: number, playerId?: number) => void;
  onSendReport?: (
    postId: string,
    reasonId: number,
    teamId?: number,
    playerId?: number,
  ) => Promise<boolean>;
}

export const PostCard = memo((props: IPostCardProps) => {
  const {
    theme,
    feedsTheme,
    isLinksClickable = false,
    hasExtendedActions = true,
    isForSidePanel = false,
    isPulledRefresher = false,
    userId,
    editorType,
    team,
    userInfo,
    favoriteTeam,
    favoritePlayer,
    postInfo,
    bookmarkDate = {
      timeOnly: '',
      relativeLong: '',
      relativeShort: '',
      full: '',
    },
    reportReasons,
    isBookmarked,
    visitedPostId,
    activeFeed,
    onTogglePostToBookmarks,
    onShareClick,
    onVotePost,
    onResetCurrentPostId,
    onCloseBookmarks,
    triggerAuthorisationCheck,
    onCloseReportsPopup,
    onSendReport,
    onDeletePost,
    onPlayerPostClick,
    onGamePostClick,
  } = props;

  const params = useParams<{
    [paths.TEAM_ID_PARAM]: string;
    [paths.PLAYER_SLUG_PARAM]: string;
  }>();

  const { playerSlug, teamId } = params;

  const titleRef = useRef<HTMLDivElement>(null);

  const [collapsedTitle, setCollapsedTitle] = useState(true);
  const [isNeedCloseTooltip, setIsNeedCloseTooltip] = useState(true);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isReportsPopupOpen, setIsReportsPopupOpen] = useState(false);

  const [scrollToRef, setShouldScrollTo] = useScrollTo<HTMLDivElement>();
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);
  const { isNativeApp } = useMainProvider();

  const navigate = useNavigate();
  const location = useLocation();

  const handleOnCloseConfirmation = useCallback(() => {
    setIsConfirmModalOpen(false);
  }, []);

  const feedTeam = useMemo(() => {
    if (postInfo.feeds.feed?.type === PostsFeedType.Team) {
      return postInfo.feeds.feed;
    }

    return null;
  }, [postInfo]);

  const feedPlayer = useMemo(() => {
    if (postInfo.feeds.feed?.type === PostsFeedType.Player) {
      return postInfo.feeds.feed;
    }

    return null;
  }, [postInfo]);

  const handleOnConfirmDeletePost = useCallback(() => {
    onDeletePost?.(postInfo.id, feedTeam?.teamId || null);
    setIsConfirmModalOpen(false);
  }, [onDeletePost, postInfo.id, feedTeam]);

  const isFavoritesFeed = useMemo(() => paths.FAVORITES_FEED === location.pathname, [location]);

  const externalFeedCardLink = useMemo(() => {
    if (feedPlayer) {
      return getPath(paths.PLAYER_PROFILE_FEED_POST, {
        [paths.PLAYER_SLUG_PARAM]: feedPlayer.slug,
        [paths.POST_ID_PARAM]: postInfo.id,
      });
    }

    if (feedTeam) {
      return getPath(paths.TEAM_FEED_POST, {
        [paths.TEAM_ID_PARAM]: feedTeam.teamId.toString(),
        [paths.POST_ID_PARAM]: postInfo.id,
      });
    }

    return getPath(paths.HOME_FEED_POST, { [paths.POST_ID_PARAM]: postInfo.id });
  }, [postInfo, feedTeam, feedPlayer]);

  const internalFeedCardLink = useMemo<string>(() => {
    if (playerSlug) {
      return getPath(paths.PLAYER_PROFILE_FEED_POST, {
        [paths.PLAYER_SLUG_PARAM]: playerSlug,
        [paths.POST_ID_PARAM]: postInfo.id,
      });
    }

    if (teamId) {
      return getPath(paths.TEAM_FEED_POST, {
        [paths.TEAM_ID_PARAM]: teamId,
        [paths.POST_ID_PARAM]: postInfo.id,
      });
    }

    return getPath(paths.HOME_FEED_POST, { [paths.POST_ID_PARAM]: postInfo.id });
  }, [postInfo, playerSlug, teamId]);

  const cardLink = useMemo(() => {
    if (isBookmarked || isFavoritesFeed) {
      return externalFeedCardLink;
    }

    return internalFeedCardLink;
  }, [isBookmarked, isFavoritesFeed, externalFeedCardLink, internalFeedCardLink]);

  const handleContentCardsClick = useCallback(() => {
    if (ContentCardsType.Game === postInfo.contentCard?.type) {
      onGamePostClick?.(
        `${postInfo.contentCard.visitorsTeamCode} vs ${postInfo.contentCard.homeTeamCode}`,
        postInfo.contentCard.gameDate,
      );
    }

    if (ContentCardsType.Player === postInfo.contentCard?.type) {
      onPlayerPostClick?.(postInfo.contentCard.fullName, postInfo.contentCard.gameDate);
    }
  }, [postInfo, onGamePostClick, onPlayerPostClick]);

  const handleBookmarkClick = useCallback(() => {
    const eventParams: IEventParams = {};

    if (FeedTypes.Team === activeFeed && feedTeam) {
      eventParams.button_tap_type = ButtonTapsEnum.TeamProfileFeedBookmark;
      eventParams.team = feedTeam.name;
    }

    if (FeedTypes.Player === activeFeed && feedPlayer) {
      eventParams.button_tap_type = ButtonTapsEnum.PlayerPostFeedBookmark;
      eventParams.player = `${feedPlayer.firstname} ${feedPlayer.lastname}`;
    }

    if (activeFeed && FeedTypes.Team !== activeFeed && FeedTypes.Player !== activeFeed) {
      eventParams.button_tap_type = ButtonTapsEnum.HomeFeedBookmark;
      eventParams.feed = activeFeed;
    }

    if (eventParams.button_tap_type) {
      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams,
      });
    }

    onTogglePostToBookmarks(postInfo.id, feedTeam?.teamId, feedPlayer?.playerId);
  }, [activeFeed, postInfo, feedTeam, feedPlayer, onTogglePostToBookmarks]);

  const handleShareClick = useCallback(() => {
    const eventParams: IEventParams = {};

    if (FeedTypes.Team === activeFeed && feedTeam) {
      eventParams.button_tap_type = ButtonTapsEnum.TeamProfileShare;
      eventParams.team = feedTeam.name;
    }

    if (FeedTypes.Player === activeFeed && feedPlayer) {
      eventParams.button_tap_type = ButtonTapsEnum.PlayerPostFeedShare;
      eventParams.player = `${feedPlayer.firstname} ${feedPlayer.lastname}`;
    }

    if (activeFeed && FeedTypes.Team !== activeFeed && FeedTypes.Player !== activeFeed) {
      eventParams.button_tap_type = ButtonTapsEnum.HomeFeedShare;
      eventParams.feed = activeFeed;
    }

    if (eventParams.button_tap_type) {
      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams,
      });
    }

    onShareClick();
  }, [activeFeed, feedTeam, feedPlayer, onShareClick]);

  const handleNavigatePostCardByComment = useCallback(() => {
    const eventParams: IEventParams = {};

    if (FeedTypes.Team === activeFeed && feedTeam) {
      eventParams.button_tap_type = ButtonTapsEnum.TeamProfileFeedComment;
      eventParams.team = feedTeam.name;
    }

    if (FeedTypes.Player === activeFeed && feedPlayer) {
      eventParams.button_tap_type = ButtonTapsEnum.PlayerPostFeedComment;
      eventParams.player = `${feedPlayer.firstname} ${feedPlayer.lastname}`;
    }

    if (activeFeed && FeedTypes.Team !== activeFeed && FeedTypes.Player !== activeFeed) {
      eventParams.button_tap_type = ButtonTapsEnum.HomeFeedComment;
      eventParams.feed = activeFeed;
    }

    if (eventParams.button_tap_type) {
      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams,
      });
    }

    if (triggerAuthorisationCheck?.()) {
      navigate(cardLink, { state: { focusEditor: true } });
    }
  }, [activeFeed, cardLink, feedPlayer, feedTeam, navigate, triggerAuthorisationCheck]);

  const postType = useMemo(() => {
    if (feedTeam) {
      return BookmarkFooterType.Team;
    }

    return BookmarkFooterType.Post;
  }, [feedTeam]);

  const footerTitle = useMemo(
    () => (feedTeam ? feedTeam.name : postInfo.title),
    [feedTeam, postInfo.title],
  );

  const handleVoteClick = useCallback(
    (reaction: string, type: ReactionDirectionType) => {
      onVotePost?.(postInfo.id, reaction, type, feedTeam?.teamId, feedPlayer?.playerId);
    },
    [postInfo, feedTeam, feedPlayer, onVotePost],
  );

  const handleContextMenuClick = useCallback(
    (barAction: BarActionType) => {
      if (barAction.type === BarAction.Click && barAction.payload === 'share') {
        handleShareClick();
      }

      if (barAction.type === BarAction.Click && barAction.payload === 'bookmark') {
        handleBookmarkClick();
      }

      if (barAction.type === BarAction.Click && barAction.payload === 'delete') {
        setIsConfirmModalOpen(true);
      }

      if (barAction.type === BarAction.Click && barAction.payload === 'report') {
        if (triggerAuthorisationCheck?.()) {
          setIsReportsPopupOpen(true);
        }
      }

      if (barAction.type === BarAction.Click && barAction.payload === 'user-report') {
        if (triggerAuthorisationCheck?.()) {
          userReportHandler();
        }
      }

      setIsNeedCloseTooltip(true);
    },
    [triggerAuthorisationCheck, handleShareClick, handleBookmarkClick],
  );

  const handleReportsPopupClose = useCallback(() => {
    onCloseReportsPopup?.(postInfo.id);
    setIsReportsPopupOpen(false);
  }, [postInfo.id, onCloseReportsPopup]);

  useEffect(() => {
    if (visitedPostId === postInfo.id && onResetCurrentPostId) {
      setShouldScrollTo(true);

      onResetCurrentPostId(null);
    }
  }, [postInfo.id, visitedPostId, setShouldScrollTo, onResetCurrentPostId]);

  const CustomTag = useMemo(() => (isLinksClickable ? 'div' : Link), [isLinksClickable]);

  const isMyPost = useMemo(() => userInfo.uuid === userId, [userInfo, userId]);

  const userSourceAvatar = useMemo(() => {
    if (postInfo.source?.author) {
      return postInfo.source?.author.profileImage;
    }

    return userInfo.smallAvatarUrl;
  }, [postInfo.source, userInfo]);

  const userSourceUsername = useMemo(() => {
    if (postInfo.source?.author?.username) {
      return postInfo.source?.author.username;
    }

    return userInfo.username;
  }, [postInfo.source, userInfo]);

  const handleContentClick = useCallback(() => {
    handleContentCardsClick();

    if (onCloseBookmarks && !isDesktopPlus) {
      onCloseBookmarks();
    }
  }, [isDesktopPlus, onCloseBookmarks, handleContentCardsClick]);

  const handleOpenSource = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      event.preventDefault();

      if (postInfo.source) {
        if (isNativeApp) {
          Browser.open({ url: postInfo.source.url });
          return;
        }

        window.open(postInfo.source.url, '_blank');
      }
    },
    [isNativeApp, postInfo.source],
  );

  const handleReportSubmit = useCallback(
    async (reasonId: number) => {
      const isSuccessResponse = await onSendReport?.(
        postInfo.id,
        reasonId,
        feedTeam?.teamId,
        feedPlayer?.playerId,
      );

      return !!isSuccessResponse;
    },
    [feedTeam, feedPlayer, postInfo.id, onSendReport],
  );

  const handleFeedClick = useCallback(
    (type: PostsFeedType, id: string) => {
      if (type === PostsFeedType.Team) {
        navigate(
          getPath(paths.TEAM_FEED, {
            [paths.TEAM_ID_PARAM]: id,
          }),
        );

        return;
      }

      if (type === PostsFeedType.Player) {
        navigate(
          getPath(paths.PLAYER_PROFILE_FEED, {
            [paths.PLAYER_SLUG_PARAM]: id,
          }),
        );

        return;
      }

      navigate(paths.HOME_FEED);
    },
    [navigate],
  );

  const handleNavigateByContentCards = useCallback(() => {
    handleContentCardsClick();
    navigate(cardLink);
  }, [cardLink, navigate, handleContentCardsClick]);

  const postCardClasses = useMemo(
    () =>
      cn(styles.PostCard, {
        [styles['PostCard--detail-theme']]: theme === PostsTheme.Detail,
        [styles['PostCard--bookmark-theme']]: theme === PostsTheme.Bookmark,
        [styles['PostCard--side-panel-theme']]: isForSidePanel,
        [styles['PostCard--freezed']]: isPulledRefresher,
        [styles['PostCard--single-title']]: !postInfo.content,
      }),
    [isPulledRefresher, isForSidePanel, theme, postInfo.content],
  );

  const postCardTitleClasses = useMemo(
    () =>
      cn(styles.PostCard__Title, {
        [styles['PostCard__Title--is-collapsed']]: collapsedTitle,
      }),
    [collapsedTitle],
  );

  // the callback shouldn't be memoized
  const getTextLinesLength = () => {
    if (titleRef.current) {
      const titleStyles = window.getComputedStyle(titleRef.current);
      const lineHeightValue = titleStyles.getPropertyValue('line-height');

      const entireHeight = titleRef.current.clientHeight;
      const liheHeight = parseInt(lineHeightValue, 10);

      const countLines = Math.round(entireHeight / liheHeight);

      if (countLines < MAX_RICH_TEXT_LINES) {
        return MAX_RICH_TEXT_LINES - countLines;
      }

      return 0;
    }

    return MAX_RICH_TEXT_LINES;
  };

  return (
    <div ref={scrollToRef} className={postCardClasses}>
      {theme !== PostsTheme.Detail && !isForSidePanel && (
        <div className={styles.PostCard__Avatar}>
          <Avatar src={userSourceAvatar} size={AvatarSize.M} username={userSourceUsername} />
        </div>
      )}
      <div className={styles.PostCard__Inner}>
        <CustomTag to={cardLink} className={styles.PostCard__Wrapper} onClick={handleContentClick}>
          <PostCardHeader
            theme={theme}
            feedsTheme={feedsTheme}
            isMyPost={isMyPost}
            createdDates={postInfo.createdDates}
            userSourceAvatar={userSourceAvatar}
            userSourceUsername={userSourceUsername}
            favoriteTeam={favoriteTeam}
            favoritePlayer={favoritePlayer}
            source={postInfo.source}
            isForSidePanel={isForSidePanel}
            isNeedCloseTooltip={isNeedCloseTooltip}
            feeds={postInfo.feeds}
            onContextMenuClick={handleContextMenuClick}
            setIsNeedCloseTooltip={setIsNeedCloseTooltip}
            onFeedClick={handleFeedClick}
            onTeamClick={props.onTeamClick}
            onPlayerClick={props.onPlayerClick}
            onSourceClick={handleOpenSource}
          />
          <div className={styles.PostCard__ContentWrapper}>
            {postInfo.title && !postInfo.contentCard && (
              <div ref={titleRef} className={postCardTitleClasses}>
                {postInfo.title}
              </div>
            )}
            {feedsTheme === PostCardFeedsTheme.List && (
              <div className={styles.PostCard__Feeds}>
                <PostCardFeeds
                  items={postInfo.feeds}
                  source={postInfo.source}
                  theme={feedsTheme}
                  onSourceClick={handleOpenSource}
                  onFeedClick={handleFeedClick}
                />
              </div>
            )}
            {postInfo.content && (
              <div className={styles.PostCard__Content}>
                <RichTextPreview
                  id={postInfo.id}
                  editorType={editorType}
                  editorState={postInfo.content}
                  getTextLinesLength={getTextLinesLength}
                  setCollapsedTitle={setCollapsedTitle}
                />
              </div>
            )}
          </div>
        </CustomTag>
        {postInfo.source?.channel === 'youtube' && (
          <YouTubePlayer sourceUrl={postInfo.source.url} />
        )}
        {postInfo.embeddedLink && theme !== PostsTheme.Bookmark && (
          <EmbeddedAttachment
            sourceUrl={postInfo.embeddedLink.url}
            embeddedType={postInfo.embeddedLink.embeddedType}
          />
        )}
        {postInfo.source?.imagePreview && postInfo.source.type === PostSourceType.News && (
          <ImageWithBadge
            imagePreviewUrl={postInfo.source.imagePreview}
            sourceUrl={postInfo.source.url}
            onImageClick={handleOpenSource}
          />
        )}
        <Attachments
          entityId={postInfo.id}
          isForBookmarks={theme === PostsTheme.Bookmark}
          author={{
            smallAvatarUrl: userSourceAvatar,
            username: userSourceUsername,
          }}
          attachments={postInfo.attachments}
          parentEntityId={postInfo.id}
          date={postInfo.createdDates.relativeLong}
          isSmall={isForSidePanel || (theme === PostsTheme.Bookmark && !isForSidePanel)}
          onPollVote={props.onPollVote}
          onOpenPreview={props.onOpenPreview}
          onPollExpired={props.onPollExpired}
          team={team}
          hasShowMore={!!postInfo.source?.hasShowMore}
          onOpenSource={handleOpenSource}
        />
        {postInfo.contentCard?.type === ContentCardsType.Player && (
          <PlayerContent
            isPosted
            theme={theme}
            contentData={postInfo.contentCard}
            onClick={handleNavigateByContentCards}
          />
        )}
        {postInfo.contentCard?.type === ContentCardsType.Game && (
          <GameContent
            isPosted
            theme={theme}
            contentData={postInfo.contentCard}
            onClick={handleNavigateByContentCards}
          />
        )}
        {theme !== PostsTheme.Bookmark && (
          <PostCardActionsBar
            theme={theme}
            activeFeed={activeFeed || null}
            isBookmarked={isBookmarked}
            feedTeam={feedTeam}
            feedPlayer={feedPlayer}
            bookmarksCount={postInfo.bookmarksCount}
            commentsCount={postInfo.commentsCount}
            reactions={postInfo.reactions}
            reactionsSum={postInfo.reactionsSum || 0}
            sharesCount={postInfo.sharesCount}
            hasExtendedActions={hasExtendedActions}
            onVoteClick={handleVoteClick}
            onCommentClick={handleNavigatePostCardByComment}
            triggerAuthorisationCheck={triggerAuthorisationCheck}
            onBookmarkClick={handleBookmarkClick}
            onShareClick={handleShareClick}
          />
        )}
        {theme === PostsTheme.Bookmark && (
          <BookmarkFooter
            type={postType}
            theme={BookmarkFooterTheme.Post}
            time={bookmarkDate.relativeLong}
            title={footerTitle}
          />
        )}
        {isConfirmModalOpen && (
          <ConfirmationModal
            content="Are you sure you want to delete this post? This cannot be undone."
            onSuccessCallback={handleOnConfirmDeletePost}
            onClose={handleOnCloseConfirmation}
            title="Delete Post"
            visible
            primaryButtonText="Delete"
            secondaryButtonText="Cancel"
          />
        )}
        {!isMyPost && theme !== PostsTheme.Bookmark && reportReasons && (
          <ReportsPopup
            reasons={reportReasons}
            visible={isReportsPopupOpen}
            onClose={handleReportsPopupClose}
            onSendReport={handleReportSubmit}
          />
        )}
      </div>
    </div>
  );
});
