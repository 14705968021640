import { FC, useEffect } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { useInjection } from 'inversify-react';

import { ContentCardsStore } from 'stores/content-cards/content-cards.store';
import { FeedTypes } from 'stores/feed-filters/enums/feed-types.enum';
import { FeedFiltersStore } from 'stores/feed-filters/feed-filters.store';

import { PageWrapperContainer } from 'containers/page-wrapper/page-wrapper.container';
import { ShortcutBarContainer } from 'containers/shortcut-bar/shortcut-bar.container';
import { WelcomePopupFeedContainer } from 'containers/welcome-popup/welcome-popup-feed.container';

import { TYPES } from 'configs/di-types.config';
import * as path from 'routes/paths.constants';

export const FeedParentRoute: FC = () => {
  const feedFiltersStore = useInjection<FeedFiltersStore>(TYPES.FeedFiltersStore);
  const contentCardsStore = useInjection<ContentCardsStore>(TYPES.ContentCardsStore);

  const location = useLocation();

  const params = useParams<{ [path.POST_ID_PARAM]: string }>();

  const { postId } = params;

  useEffect(() => {
    contentCardsStore.setEnabledRealtimeConnections(true);

    return () => {
      contentCardsStore.setEnabledRealtimeConnections(false);
    };
  }, [contentCardsStore]);

  useEffect(() => {
    switch (location.pathname) {
      case path.HOME_FEED:
        feedFiltersStore.setActiveFeed(FeedTypes.NBA);
        break;
      case path.FAVORITES_FEED:
        feedFiltersStore.setActiveFeed(FeedTypes.Favorites);
        break;
      case path.HOME_HEADLINES:
        feedFiltersStore.setActiveFeed(FeedTypes.Headlines);
        break;
      default:
        feedFiltersStore.setActiveFeed(null);
        break;
    }
  }, [location.pathname, feedFiltersStore]);

  if (postId) {
    return <Outlet />;
  }

  return (
    <PageWrapperContainer>
      <WelcomePopupFeedContainer />
      <ShortcutBarContainer />
      <Outlet />
    </PageWrapperContainer>
  );
};
