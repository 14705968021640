import { useCallback, useMemo } from 'react';
import { createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';
import { IEventParams } from 'services/google-analytic/interfaces/event-payload.interface';

import { FeedTypes } from 'stores/feed-filters/enums/feed-types.enum';
import { FeedFiltersStore } from 'stores/feed-filters/feed-filters.store';
import { LayoutEntity } from 'stores/layout/enums/layout-entity.enum';
import { MetaTagsStore } from 'stores/meta-tags/meta-tags.store';
import { PlayerStore } from 'stores/player/player.store';
import { ShareStore } from 'stores/share/share.store';
import { TeamsStore } from 'stores/teams/teams.store';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { stringIncludesOneOf } from 'containers/layout/layout.utils';
import { RANKING_CATEGORIES } from 'containers/player-rankings/player-rankings.config';

import {
  COMMUNITY_HEADER_INFO_GROUPS,
  PLAYER_PROFILE_HEADER_INFO_GROUPS,
} from 'configs/context-menu-groups.config';
import { DEFAULT_GREY_ICON_COLOR, SELECTED_COLOR } from 'configs/controls.config';
import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { DEFAULT_SHARE_TITLE } from 'configs/share.config';
import { getPath } from 'helpers/get-path.util';
import * as path from 'routes/paths.constants';
import { STATS_PART } from 'routes/paths.constants';

import { useGaButtonCustomTap } from 'hooks/use-ga-button-custom-tap';
import { useLayoutEntity } from 'hooks/use-layout-entity';
import { useMainProvider } from 'hooks/use-main-provider';
import { useNativeShare } from 'hooks/use-native-share';
import { useResponsive } from 'hooks/use-responsive';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { GameDetailsTabs } from 'components/game/game.config';
import { ContextMenuTooltip } from 'components/ui/context-menu-tooltip/context-menu-tooltip.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import styles from './header-struture.module.less';

export const HeaderStructureContainer = observer(() => {
  const feedFiltersStore = useInjection<FeedFiltersStore>(TYPES.FeedFiltersStore);
  const teamsStore = useInjection<TeamsStore>(TYPES.TeamsStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);
  const shareStore = useInjection<ShareStore>(TYPES.ShareStore);
  const metaTagsStore = useInjection<MetaTagsStore>(TYPES.MetaTagsStore);
  const playerStore = useInjection<PlayerStore>(TYPES.PlayerStore);

  const { isNativeApp } = useMainProvider();
  const { shareByNativeModal } = useNativeShare();

  const params = useParams<{
    [path.PLAYER_SLUG_PARAM]: string;
    [path.GAME_ID_PARAM]: string;
    [path.CATEGORY_PARAM]: string;
  }>();

  const { playerSlug, gameId, category } = params;

  const location = useLocation();
  const navigate = useNavigate();
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const { googleAnalyticsButtonTap } = useGaButtonCustomTap();

  const currentTeam = useMemo(
    () => teamsStatsStore.findTeamById(teamsStore.teamId),
    [teamsStatsStore, teamsStore.teamId],
  );

  const handleOpenFilters = useCallback(() => {
    googleAnalyticsButtonTap(ButtonTapsEnum.FilterIconClick);
    feedFiltersStore.setIsFilterOpen(true);
  }, [feedFiltersStore, googleAnalyticsButtonTap]);

  const handleOpenArena = useCallback(() => {
    if (gameId) {
      const arenaGamePath = getPath(path.ARENA_GAME, {
        [path.GAME_ID_PARAM]: gameId,
      });
      navigate({
        pathname: arenaGamePath,
        search: `${createSearchParams({ tab: GameDetailsTabs.PLAYS })}`,
      });
    }
  }, [gameId, navigate]);

  const handleSharePlayerPerformance = useCallback(async () => {
    if (gameId && playerSlug) {
      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: ButtonTapsEnum.PlayerPerformanceShare,
          player: playerSlug,
        },
      });

      await shareStore.fetchSharePlayerPerformance(gameId, playerSlug);

      if (isNativeApp && shareStore.shareData) {
        shareByNativeModal(
          shareStore.shareData.link,
          metaTagsStore.metaTags?.title || DEFAULT_SHARE_TITLE,
        );
      } else {
        shareStore.setIsShareModalVisible(true);
      }
    }
  }, [isNativeApp, playerSlug, gameId, metaTagsStore.metaTags, shareStore, shareByNativeModal]);

  const handleSharePlayersRankings = useCallback(async () => {
    await shareStore.fetchSharePlayersRankings();

    GoogleAnalyticService.event({
      eventName: 'button_custom_tap',
      eventParams: {
        button_tap_type: ButtonTapsEnum.RankingsShare,
      },
    });

    if (isNativeApp && shareStore.shareData) {
      shareByNativeModal(shareStore.shareData.link, 'Rankings');
    } else {
      shareStore.setIsShareModalVisible(true);
    }
  }, [isNativeApp, shareStore, shareByNativeModal]);

  const handleSharePlayersRankingsDetails = useCallback(async () => {
    if (category) {
      await shareStore.fetchSharePlayersRankingsDetails(category);
      const categoryTitle = RANKING_CATEGORIES.find((item) => item.key === category)?.label || '';

      if (isNativeApp && shareStore.shareData) {
        shareByNativeModal(shareStore.shareData.link, categoryTitle);
      } else {
        shareStore.setIsShareModalVisible(true);
      }
    }
  }, [category, isNativeApp, shareStore, shareByNativeModal]);

  const handleSearchClick = useCallback(() => {
    navigate(path.SEARCH);

    if (location.pathname.includes(STATS_PART)) {
      googleAnalyticsButtonTap(ButtonTapsEnum.StatsSearch);

      return;
    }

    const eventParams: IEventParams = {};

    const player = playerStore.playerDetails;

    if (FeedTypes.Player === feedFiltersStore.activeFeed && player) {
      eventParams.button_tap_type = ButtonTapsEnum.PlayerSearch;
      eventParams.player = `${player.firstname} ${player.lastname}`;
    }

    if (FeedTypes.Team === feedFiltersStore.activeFeed && teamsStatsStore.team) {
      eventParams.button_tap_type = ButtonTapsEnum.TeamSearch;
      eventParams.team = teamsStatsStore.team.name;
    }

    if (
      feedFiltersStore.activeFeed &&
      FeedTypes.Player !== feedFiltersStore.activeFeed &&
      FeedTypes.Team !== feedFiltersStore.activeFeed
    ) {
      eventParams.button_tap_type = ButtonTapsEnum.HomeSearch;
      eventParams.feed = feedFiltersStore.activeFeed;
    }

    if (eventParams.button_tap_type) {
      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams,
      });
    }
  }, [
    playerStore.playerDetails,
    feedFiltersStore.activeFeed,
    teamsStatsStore.team,
    location.pathname,
    navigate,
    googleAnalyticsButtonTap,
  ]);

  const handleContextItemClick = useCallback(
    async (barAction: BarActionType) => {
      if (barAction.type === BarAction.Click && barAction.payload === 'leave') {
        await teamsStore.unfollowTeam();
      }
    },
    [teamsStore],
  );

  const handleContextPlayerProfileClick = useCallback(
    async (barAction: BarActionType) => {
      if (barAction.type === BarAction.Click && barAction.payload === 'leave') {
        playerStore.unfollowPlayer();
      }
    },
    [playerStore],
  );

  const isShowFilterButton = useMemo(() => {
    const includedFilterButton = stringIncludesOneOf(location.pathname, [
      path.FEED_PART,
      path.FAVORITES_PART,
      path.HOME_HEADLINES,
    ]);
    const excludedFilterButton = stringIncludesOneOf(location.pathname, [path.PLAYERS_PART]);

    return includedFilterButton && !excludedFilterButton;
  }, [location.pathname]);

  const isPerformancePage = useMemo(() => {
    return stringIncludesOneOf(location.pathname, [path.PERFORMANCE_PART]);
  }, [location.pathname]);

  const isPlayersRankingPage = useMemo(() => {
    if (stringIncludesOneOf(location.pathname, [path.DETAILS_PART])) {
      return false;
    }

    return stringIncludesOneOf(location.pathname, [path.RANKINGS_PART]);
  }, [location.pathname]);

  const isPlayersRankingDetailsPage = useMemo(() => {
    return stringIncludesOneOf(location.pathname, [`/${path.DETAILS_PART}/`]);
  }, [location.pathname]);

  const isTeamPage = useMemo(() => {
    return stringIncludesOneOf(location.pathname, [`/${path.TEAMS_PART}/`]);
  }, [location.pathname]);

  const isPlayerProfilePage = useMemo(() => {
    return stringIncludesOneOf(location.pathname, [`/${path.PLAYERS_PART}/`]);
  }, [location.pathname]);

  const isShowSearchButton = useMemo(() => {
    if (isDesktopPlus) {
      return false;
    }

    if (stringIncludesOneOf(location.pathname, [path.PERFORMANCE_PART, path.DETAILS_PART])) {
      return false;
    }

    return stringIncludesOneOf(location.pathname, [
      path.FEED_PART,
      path.FAVORITES_PART,
      path.STANDINGS_PART,
      path.STATS_PART,
      path.SCORES_PART,
      path.TEAMS_PART,
      path.HOME_HEADLINES,
      path.PLAYERS_PART,
      path.RANKINGS_PART,
    ]);
  }, [location.pathname, isDesktopPlus]);

  const customFilterColor = useMemo(() => {
    if (
      feedFiltersStore.activeFeed === FeedTypes.Headlines ||
      feedFiltersStore.areFiltersInDefaultState
    ) {
      return currentTeam?.secondaryTextColor;
    }

    return SELECTED_COLOR;
  }, [currentTeam, feedFiltersStore.activeFeed, feedFiltersStore.areFiltersInDefaultState]);

  const isDisabledFilter = useMemo(
    () => feedFiltersStore.activeFeed === FeedTypes.Headlines,
    [feedFiltersStore.activeFeed],
  );

  useLayoutEntity({
    type: LayoutEntity.HeaderRight,
    value: () => (
      <div className={styles.HeaderRight}>
        {isShowSearchButton && (
          <IconButton
            iconName={IconFontName.Search2}
            onClick={handleSearchClick}
            theme={IconButtonTheme.Transparent}
            customColor={currentTeam ? currentTeam?.secondaryTextColor : DEFAULT_GREY_ICON_COLOR}
          />
        )}
        {isShowFilterButton && (
          <IconButton
            iconName={IconFontName.Filter}
            onClick={handleOpenFilters}
            customColor={customFilterColor}
            theme={IconButtonTheme.Transparent}
            disabled={isDisabledFilter}
          />
        )}
        {isPerformancePage && (
          <>
            <IconButton
              iconName={IconFontName.Arena}
              onClick={handleOpenArena}
              theme={IconButtonTheme.Transparent}
            />
            <IconButton
              iconName={IconFontName.Share}
              onClick={handleSharePlayerPerformance}
              theme={IconButtonTheme.Transparent}
            />
          </>
        )}
        {isPlayersRankingPage && (
          <IconButton
            iconName={IconFontName.Share}
            onClick={handleSharePlayersRankings}
            theme={IconButtonTheme.Transparent}
          />
        )}
        {isPlayersRankingDetailsPage && (
          <IconButton
            iconName={IconFontName.Share}
            onClick={handleSharePlayersRankingsDetails}
            theme={IconButtonTheme.Transparent}
          />
        )}
        {isTeamPage && teamsStore.isFollowedTeam && (
          <ContextMenuTooltip
            groups={COMMUNITY_HEADER_INFO_GROUPS}
            onItemClick={handleContextItemClick}
            toggleOnClick
            tooltipProps={{ tooltipOffset: 8, placement: 'bottom-end' }}
          >
            <IconButton
              iconName={IconFontName.More}
              theme={IconButtonTheme.Secondary}
              customColor={currentTeam?.secondaryTextColor}
            />
          </ContextMenuTooltip>
        )}
        {isPlayerProfilePage && playerStore.isFollowedPlayer && (
          <ContextMenuTooltip
            groups={PLAYER_PROFILE_HEADER_INFO_GROUPS}
            onItemClick={handleContextPlayerProfileClick}
            toggleOnClick
            tooltipProps={{ tooltipOffset: 8, placement: 'bottom-end' }}
          >
            <IconButton
              iconName={IconFontName.More}
              theme={IconButtonTheme.Secondary}
              customColor={DEFAULT_GREY_ICON_COLOR}
            />
          </ContextMenuTooltip>
        )}
      </div>
    ),
  });

  return null;
});
