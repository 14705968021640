import { FEED_SLIDER_ITEMS, FILTER_SLIDER_ITEMS } from 'containers/feed-filters/feed.config';

import { BarAction } from '../bars/bar-action.enum';

export const formSelectedSliderItems = (pathname: string, isFilters?: boolean) => {
  const items = isFilters ? FILTER_SLIDER_ITEMS : FEED_SLIDER_ITEMS;

  return items.map((item) => {
    if (item.action.type === BarAction.Link) {
      return {
        ...item,
        isSelected: pathname.includes(item.action.path),
      };
    }

    return item;
  });
};
