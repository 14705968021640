import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import cn from 'classnames';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { AuthStore } from 'stores/auth/auth.store';
import { FeedTypes } from 'stores/feed-filters/enums/feed-types.enum';
import { FeedFiltersStore } from 'stores/feed-filters/feed-filters.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { PostsStore } from 'stores/posts/posts.store';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { SCROLL_TOP_ELEMENT } from 'configs/controls.config';
import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { TEAM_ID_PARAM } from 'routes/paths.constants';

import { useGaButtonCustomTap } from 'hooks/use-ga-button-custom-tap';
import { useResponsive } from 'hooks/use-responsive';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { formSelectedSliderItems } from 'components/feed-filter/form-selected-slider-items.util';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { FeedsSelectSlider } from 'components/ui/feeds-select-slider/feeds-select-slider.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import styles from './header-feed.module.less';

export const HeaderFeedContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);
  const feedFiltersStore = useInjection<FeedFiltersStore>(TYPES.FeedFiltersStore);
  const postsStore = useInjection<PostsStore>(TYPES.PostsStore);

  const params = useParams<{ [TEAM_ID_PARAM]: string }>();

  const { teamId } = params;

  const location = useLocation();
  const navigate = useNavigate();

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const { googleAnalyticsButtonTap } = useGaButtonCustomTap();

  const createButtonText = useMemo(() => (isDesktopPlus ? 'Create Post' : 'Post'), [isDesktopPlus]);

  const sliderItems = useMemo(
    () => formSelectedSliderItems(location.pathname),
    [location.pathname],
  );

  const headerFeedSliderClasses = useMemo(
    () =>
      cn(styles.HeaderFeedSlider, {
        [styles['HeaderFeedSlider--fixed']]: layoutStore.isFixedSliderToggle,
        [styles['HeaderFeedSlider--hidden']]: layoutStore.isHiddenSliderToggle,
      }),
    [layoutStore.isFixedSliderToggle, layoutStore.isHiddenSliderToggle],
  );

  const isPlayerFeed = useMemo(
    () => feedFiltersStore.activeFeed === FeedTypes.Player,
    [feedFiltersStore.activeFeed],
  );

  const isHeadlinesFeed = useMemo(
    () => feedFiltersStore.activeFeed === FeedTypes.Headlines,
    [feedFiltersStore.activeFeed],
  );

  const handleSliderItemClick = useCallback(
    (action: BarActionType, value: FeedTypes) => {
      if (action.type === BarAction.Link) {
        feedFiltersStore.setActiveFeed(value);

        document.getElementById(SCROLL_TOP_ELEMENT)?.scrollIntoView();

        navigate(action.path);
      }
    },
    [feedFiltersStore, navigate],
  );

  const handleOpenCreatePostModal = useCallback(() => {
    GoogleAnalyticService.event({
      eventName: 'button_custom_tap',
      eventParams: {
        button_tap_type: ButtonTapsEnum.CreatePost,
        team: teamsStatsStore.team?.name || 'null',
      },
    });

    if (!authStore.triggerAuthorisationCheck()) {
      return;
    }

    if (!postsStore.postTags.length) {
      postsStore.getPostTags();
    }

    postsStore.setIsPostCreationModalVisible(true);
  }, [postsStore, teamsStatsStore.team, authStore]);

  const handleOpenFilters = useCallback(() => {
    feedFiltersStore.setIsFilterOpen(true);

    googleAnalyticsButtonTap(ButtonTapsEnum.FilterIconClick);
  }, [feedFiltersStore, googleAnalyticsButtonTap]);

  if (isPlayerFeed) {
    return null;
  }

  return (
    <>
      {isDesktopPlus && (
        <div className={styles.HeaderFeed}>
          <Button
            theme={ButtonTheme.Primary}
            size={ButtonSize.Small}
            onClick={handleOpenCreatePostModal}
            disabled={isHeadlinesFeed}
          >
            {createButtonText}
          </Button>
          {!teamId && (
            <div className={styles.HeaderFeedSlider}>
              <FeedsSelectSlider
                fullWidth
                items={sliderItems}
                onItemClick={handleSliderItemClick}
              />
            </div>
          )}
          <Button
            selected={!feedFiltersStore.areFiltersInDefaultState}
            theme={ButtonTheme.Tertiary}
            iconName={IconFontName.Filter}
            size={ButtonSize.Small}
            onClick={handleOpenFilters}
            disabled={isHeadlinesFeed}
          >
            Filters
          </Button>
        </div>
      )}
      {!isDesktopPlus && !teamId && (
        <div className={styles.HeaderFeed}>
          <div className={headerFeedSliderClasses}>
            <FeedsSelectSlider items={sliderItems} onItemClick={handleSliderItemClick} fullWidth />
          </div>
        </div>
      )}
    </>
  );
});
