import { inject, injectable } from 'inversify';
import { comparer, reaction } from 'mobx';

import { IPostResponse } from 'services/posts/interfaces/posts-response.interface';
import { PostsService } from 'services/posts/posts.service';

import { AdvancedEntriesStore } from 'stores/advanced-entries/advanced-entries.store';
import { FeedTypes } from 'stores/feed-filters/enums/feed-types.enum';
import { FeedFiltersStore } from 'stores/feed-filters/feed-filters.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { publicationAdapter } from 'stores/posts/adapters/publication-adapter.util';
import { IPost, IPostAdapter } from 'stores/posts/interfaces/post.interface';
import { IPostsResponse } from 'stores/posts/interfaces/post-response.inerface';

import { TYPES } from 'configs/di-types.config';

import { PAGINATION_LIMIT } from './feeds.config';

@injectable()
export class NBAFeedStore extends AdvancedEntriesStore<IPost, IPostResponse, IPostsResponse> {
  private readonly postsService: PostsService;

  private readonly feedFiltersStore: FeedFiltersStore;

  private readonly layoutStore: LayoutStore;

  constructor(
    @inject(TYPES.PostsService) postsService: PostsService,
    @inject(TYPES.FeedFiltersStore) feedFiltersStore: FeedFiltersStore,
    @inject(TYPES.LayoutStore) layoutStore: LayoutStore,
  ) {
    super(PAGINATION_LIMIT);

    this.postsService = postsService;

    this.feedFiltersStore = feedFiltersStore;

    this.layoutStore = layoutStore;

    reaction(
      () =>
        JSON.stringify([
          this.feedFiltersStore.activeFeed,
          this.feedFiltersStore.filtersForPosts,
          this.feedFiltersStore.fetched,
        ]),
      () => this.forceFetchToRefresh(),
      {
        equals: comparer.shallow,
      },
    );
  }

  public async fetchNext(): Promise<void> {
    if (this.feedFiltersStore.activeFeed === FeedTypes.NBA) {
      await this.retrieveNext(this.fetchNBAFeedPosts(), <IPostAdapter>publicationAdapter);
    }
  }

  public async fetchNBAFeedPosts(): Promise<IResponse<IPostsResponse>> {
    return this.postsService.fetchPosts(
      this.pagination,
      String(this.feedFiltersStore.filtersForPosts),
    );
  }

  public async forceFetchToRefresh() {
    if (this.feedFiltersStore.activeFeed === FeedTypes.NBA && this.feedFiltersStore.fetched) {
      await this.refresh();

      await this.forceRefresh(
        this.fetchNBAFeedPosts(),
        <IPostAdapter>publicationAdapter,
        this.layoutStore.setPulledRefresher,
      );
    }
  }

  public async refresh() {
    this.setFetched(false);
    this.setFetching(false);

    this.setIsLastPage(false);
    this.setCurrentPage(1);

    await this.initialise();
  }
}
